import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AboutPage, ContactPage, CookiesPolicyPage, HomePage, PortfolioPage, PrivacyPolicy, TermsOfUsePage } from "../pages/_index.pages";

// import HomePage from "../pages/Home/Home";
// import ContactPage from "../pages/Contact/Contact";
// import ProductsPage from "../pages/Products/Procuts";
// import SubCategories from "../pages/Products/SubCategories/SubCategories";

// import { routes } from "./routes";

export const RoutesPath = {
  dir: "/",
  home: "/home",
  contact: "/contact",
  about: "/about",
  portfolio: "/portfolio",
  cookies: "/cookies",
  terms: "/terms",
  privacy: "/privacy",
};

export default function AppRoutes() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <Routes>
          <Route path={RoutesPath.dir} element={<HomePage />} />
          <Route path={RoutesPath.home} element={<HomePage />} />
          <Route path={RoutesPath.contact} element={<ContactPage />} />
          <Route path={RoutesPath.about} element={<AboutPage />} />
          <Route path={RoutesPath.portfolio} element={<PortfolioPage />} />
          <Route path={RoutesPath.cookies} element={<CookiesPolicyPage />} />
          <Route path={RoutesPath.terms} element={<TermsOfUsePage />} />
          <Route path={RoutesPath.privacy} element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  )
};