import StructurePages from "../StructurePages";

import QrCode from "../../assets/img/qrcode-whatsapp.png";
import SeedzTechForm from "../../assets/img/seedzTechForm.png";

import {
  ContactPageHooks,
  TContactPageHooksStates
} from "./_index.contact";
import { Input } from "../../components/_index.components";

export default function ContactPage() {
  window.scrollTo(0, 0);

  const limiteCaracter = 1000;

  const states: TContactPageHooksStates = ContactPageHooks({});

  return (
    <StructurePages
      module={
        <>
          <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:gap-16 mt-8 nd:mt-0 md:mt-0 lg:mt-16 py-20">
            <div className="w-full inline-flex nd:flex nd:flex-col px-16 nd:px-5 md:px-5 lg:px-5 gap-20">
              <div
                onClick={() => window.open("https://wa.me/5511965892552?text=Olá%20venho%20do%20site%20e%20gostaria%20de%20mais%20informações%20sobre%20a%20SeedzTech")}
                className="w-auto flex flex-col justify-start items-center pt-20 nd:pt-0 cursor-pointer">
                <p className="text-center">Whatsapp</p>
                <img
                  className="w-auto nd:max-w-[50%]"
                  src={QrCode}
                  alt="qrcode"
                />
              </div>
              <form
                action="https://seedztech.com.br/sendEmail.php"
                method="post"
                name="form"
                className="w-full gap-8 pb-20 flex flex-col justify-center text-white items-center rounded-md bg-[#A4CF90]"
              >
                <img
                  className="w-auto max-w-[30%] nd:max-w-[50%]"
                  src={SeedzTechForm}
                  alt="logoseedztech"
                />
                <ul className="w-full gap-8 flex flex-col pt-0 p-20 nd:p-2 md:p-2 lg:p-2">
                  <li className="flex flex-col gap-3 w-full">
                    <label htmlFor="fullname">Nome Completo <strong className="text-red-600">*</strong></label>
                    <Input
                      id="fullname"
                      name="fullname"
                      type="text"
                      setValue={states.setFullName}
                      value={states.fullName}
                    />
                  </li>
                  <li className="flex flex-col gap-3 w-full">
                    <label htmlFor="company">Empresa <strong className="text-red-600">*</strong></label>
                    <Input
                      id="company"
                      name="company"
                      type="text"
                      setValue={states.setCompany}
                      value={states.company}
                    />
                  </li>
                  <li className="flex flex-col gap-3 w-full">
                    <label htmlFor="phoneNumber">Telefone <strong className="text-red-600">*</strong></label>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      setValue={states.setPhoneNumber}
                      value={states.phoneNumber}
                    />
                  </li>
                  <li className="flex flex-col gap-3 w-full">
                    <label htmlFor="email">Email <strong className="text-red-600">*</strong></label>
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      setValue={states.setEmail}
                      value={states.email}
                    />
                  </li>
                  <li className="flex flex-col gap-3 w-full">
                    <label htmlFor="message">Mensagem <strong className="text-red-600">*</strong></label>
                    <textarea
                      spellCheck={false}
                      className="bg-slate-100 rounded-md text-black w-full h-full p-3 min-h-[19vh] max-w-full relative"
                      value={states.message}
                      onChange={(e) => states.setMessage(e.target.value)}
                      name="message"
                    />
                    <div className="absolute bottom-0 right-0 text-[10px] p-2">
                      <p
                        className={
                          states.message?.length >= limiteCaracter
                            ? "text-red-600 font-bold"
                            : "text-primary"
                        }
                      >
                        {
                          states.message?.length}/{limiteCaracter}
                      </p>
                    </div>
                  </li>
                </ul>
                <button
                  type="submit"
                  className="w-auto max-w-[50%] min-w-[30%] h-auto p-2 px-5 border border-[#39733D] rounded-full bg-[#39733D]"
                >
                  <p className="whitespace-nowrap text-white">Enviar</p>
                </button>
              </form>

            </div>
          </section>
        </>
      }
    />
  )
};