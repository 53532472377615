import LogoOkearo from "../../../assets/img/okearo.png";
import LogoFCosta from "../../../assets/img/fcosta.png";
import LogoMachado from "../../../assets/img/machado.png";
import { THomePageProps } from "../HomePage";
import { Navigate } from "react-router-dom";
import { RoutesPath } from "../../../routes/AppRoutes.routes";
import { useState } from "react";

type TCustomer = {
  logo: any;
  alt: string;
};


type THomePageHooksProps = THomePageProps & {};
export type THomePageHooksStates = THomePageHooksProps & {
  customers: TCustomer[];
  naviteTo: string;
  setNavigateTo: (d: string) => void;
};

export const HomePageHooks = ({ ...props }: THomePageHooksProps): THomePageHooksStates => {
  const [naviteTo, setNavigateTo] = useState<string>("");

  const customers: TCustomer[] = [
    {
      logo: LogoOkearo,
      alt: "okeraro"
    },
    {
      logo: LogoFCosta,
      alt: "fcosta"
    },
    {
      logo: LogoMachado,
      alt: "machado"
    },
  ];

  return {
    ...props,
    customers,
    naviteTo,
    setNavigateTo,
  }
};