import StructurePages from "../StructurePages";

import CaseEcommerce from "../../assets/img/caseEcommerce.png";
import CaseEcommerceOkearo from "../../assets/img/ecommerceOkearo.png";
import CaseFCosta from "../../assets/img/caseFCosta.png";

import { HomePageHooks, THomePageHooksStates } from "./_index.home";
import { RoutesPath } from "../../routes/AppRoutes.routes";
import { Navigate } from "react-router-dom";

export type THomePageProps = {

};

export default function HomePage({ ...props }: THomePageProps) {
  window.scrollTo(0, 0);

  const states: THomePageHooksStates = HomePageHooks({ ...props } as THomePageProps);

  return (
    <>
      {
        states.naviteTo &&
        <Navigate to={states.naviteTo} replace={true} />
      }
      <StructurePages
        module={
          <>
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-32 nd:gap-16 mt-8 nd:mt-16 md:mt-16">
              <span className="w-full h-auto flex justify-center items-center flex-col">
                <p className="text-2xl nd:text-base whitespace-nowrap">Seu destino tecnológico começa aqui</p>
                <p className="text-3xl nd:text-lg text-bold whitespace-nowrap">Inovação personalizada para a sua empresa</p>
              </span>
              <div className="flex flex-col w-full h-auto">
                <div className="w-full flex justify-center items-center">
                  <h1 className="text-5xl nd:text-3xl text-bold whitespace-nowrap">Nossas áreas de atuação</h1>
                </div>
              </div>
            </section>
            <section className="grid grid-cols-3 nd:flex nd:flex-col md:flex md:flex-col lg:flex lg:flex-col justify-center items-center w-full h-full gap-8 nd:gap-4 mt-20 nd:mt-4">
              <div className="justify-center items-center flex flex-grow h-full">
                <div className="rounded-figma p-8 text-lg w-full h-full bg-gray-400 bg-opacity-15">
                  <p className="font-bold text-center">
                    Criação de Websites e Apps
                  </p>
                  <br />
                  <p>
                    Criação de websites e aplicativos com tecnologias avançadas. Além disso, garantimos a
                    atualização contínua do seu site e funcionalidades competitivas após o lançamento.
                    Nosso compromisso consolida uma análise de dados segmentados, maximizando o retorno
                    sobre o investimento e os resultados alcançados.
                  </p>
                </div>
              </div>
              <div className="justify-center items-center flex flex-grow h-full">
                <div className="rounded-figma p-8 text-lg w-full h-full bg-[#A4CF90] bg-opacity-15">
                  <p className="font-bold text-center xl:whitespace-normal whitespace-nowrap">Desenvolvimento de E-commerce</p>
                  <br />
                  <p>
                    Soluções tecnológicas inovadoras para construir e otimizar plataformas de e-commerce
                    exclusivas, garantindo uma experiência de compra excepcional. Levamos em consideração o
                    produto, o público-alvo e a identidade da marca para oferecer funcionalidades
                    exclusivas e garantir o sucesso do seu negócio online.
                  </p>
                </div>
              </div>
              <div className="justify-center items-center flex flex-grow h-full">
                <div className="rounded-figma p-8 text-lg w-full h-full bg-[#39733D] bg-opacity-15">
                  <p className="font-bold text-center">Consultoria tecnológica</p>
                  <br />
                  <p>
                    Consultoria personalizada para alinhar tecnologia e objetivos de negócio, desenvolvendo
                    soluções sob medida para suas metas. Acompanhamento de cada etapa do processo, garantindo
                    funcionalidade e progresso até a conclusão do projeto. Nosso foco é maximizar o sucesso
                    da sua iniciativa através de análises contínuas e ajustes conforme necessário.
                  </p>
                </div>
              </div>
            </section>
            <section className="flex flex-col justify-center items-center w-full h-full gap-8 mt-20 nd:gap-4">
              <div className="text-gray-400 flex gap-2 flex-col w-full justify-center items-center">
                <h3 className="text-5xl nd:text-3xl">Conquistas Alcançadas e Projetos Executados </h3>
                <h4 className="text-2xl nd:text-base">Transformando obstáculos em oportunidades e ideias em realidade</h4>
              </div>
              <div className="w-full flex nd:flex-col items-center justify-between mt-20 nd:mt-0">
                <img
                  className="w-auto max-w-[40%] nd:hidden"
                  src={CaseEcommerce}
                />
                <div className="rounded-figma h-auto w-auto 2xl:max-w-[40%] text-gray-400 p-8 gap-4 flex flex-col">
                  <h1 className="text-3xl text-[#39733D] font-bold">Gergelim</h1>
                  <div className="w-full flex flex-col gap-2">
                    <hr className="h-px bg-gray-400 w-full" />
                    <p className="text-xl">
                      O sistema permite gerenciar todo conteúdo do e-commerce de forma personalizada
                      garantindo que cada Website reflita a identidade de sua marca de maneira única.
                    </p>
                  </div>
                  <button
                    className="w-auto max-w-[50%] nd:max-w-[70%] h-auto p-2 px-5 border border-[#39733D] rounded-full bg-[#39733D]"
                    onClick={() => states.setNavigateTo(RoutesPath.portfolio)}
                  >
                    <p className="whitespace-nowrap text-white">Visualizar projeto</p>
                  </button>
                </div>
              </div>
              <div className="w-full flex nd:flex-col items-center justify-between mt-20 nd:mt-0">
                <div className="rounded-figma h-full w-auto nd:w-full text-gray-400 p-8 gap-4 flex flex-col">
                  <h1 className="text-3xl text-[#A4CF90] font-bold">Okearo</h1>
                  <div className="w-full flex flex-col gap-2">
                    <hr className="h-px bg-gray-400 w-full" />
                    <p className="text-xl">
                      Plataforma de E-commerce PWA.
                    </p>
                  </div>
                  <button className="w-auto max-w-[70%] nd:max-w-[70%] h-auto p-2 px-5 border border-[#A4CF90] rounded-full bg-[#A4CF90]">
                    <p className="whitespace-nowrap text-white">Visualizar projeto</p>
                  </button>
                </div>
                <img
                  className="w-auto max-w-[40%] nd:hidden"
                  src={CaseEcommerceOkearo}
                />
              </div>
              <div className="w-full flex nd:flex-col items-center justify-between mt-20 nd:mt-0">
                <img
                  className="w-auto max-w-[40%] nd:hidden"
                  src={CaseFCosta}
                />
                <div className="rounded-figma h-full w-auto 2xl:max-w-[40%] text-gray-400 p-8 gap-4 flex flex-col">
                  <h1 className="text-3xl text-[#39733D] font-bold">F | Costa</h1>
                  <div className="w-full flex flex-col gap-2">
                    <hr className="h-px bg-gray-400 w-full" />
                    <p className="text-xl">
                      A plataforma permite criar orçamentos detalhados, gerenciar projetos e acompanhar o progresso em tempo real.
                      Clientes acessam documentos e recebem atualizações contínuas, facilitando a colaboração e garantindo a
                      execução conforme o prazo e orçamento.
                    </p>
                  </div>
                  <button className="w-auto max-w-[50%] nd:max-w-[70%] h-auto p-2 px-5 border border-[#39733D] rounded-full bg-[#39733D]">
                    <p className="whitespace-nowrap text-white">Visualizar projeto</p>
                  </button>
                </div>
              </div>
            </section>
            <hr className="h-px bg-gray-400 w-full" />
            <section className="flex flex-col justify-center items-center w-full h-full gap-8 my-20 nd:gap-4 overflow-hidden">
              <div className="flex gap-4 whitespace-nowrap overflow-hidden">
                {
                  states.customers?.map((item, i) => (
                    <div
                      key={i}
                      className="w-[16vw] h-[16vw] nd:w-[35vw] nd:h-[35vw] flex justify-center items-center rounded-md p-2  animate-marquee"
                    >
                      <img
                        className="w-full h-full object-contain rounded-md filter grayscale hover:grayscale-0 transition duration-300"
                        src={item.logo}
                        alt={item.alt}
                      />
                    </div>
                  ))
                }
                {
                  states.customers?.map((item, i) => (
                    <div
                      key={i}
                      className="w-[16vw] h-[16vw] nd:w-[35vw] nd:h-[35vw] flex justify-center items-center rounded-md p-2  animate-marquee"
                    >
                      <img
                        className="w-full h-full object-contain rounded-md filter grayscale hover:grayscale-0 transition duration-300"
                        src={item.logo}
                        alt={item.alt}
                      />
                    </div>
                  ))
                }
              </div>
              <div className="flex gap-4 whitespace-nowrap overflow-hidden">
                {
                  states.customers
                    ?.reverse()
                    ?.map((item, i) => (
                      <div
                        key={i}
                        className="w-[16vw] h-[16vw] nd:w-[35vw] nd:h-[35vw] flex justify-center items-center rounded-md p-2  animate-marquee"
                      >
                        <img
                          className="w-full h-full object-contain rounded-md filter grayscale hover:grayscale-0 transition duration-300"
                          src={item.logo}
                          alt={item.alt}
                        />
                      </div>
                    ))
                }
                {
                  states.customers?.map((item, i) => (
                    <div
                      key={i}
                      className="w-[16vw] h-[16vw] nd:w-[35vw] nd:h-[35vw] flex justify-center items-center rounded-md p-2  animate-marquee"
                    >
                      <img
                        className="w-full h-full object-contain rounded-md filter grayscale hover:grayscale-0 transition duration-300"
                        src={item.logo}
                        alt={item.alt}
                      />
                    </div>
                  ))
                }
              </div>
            </section>
          </>
        }
      />
    </>
  )
};

