import { StructurePages } from "../_index.pages";

export type TPrivacyPolicyProps = {};

export default function PrivacyPolicy({ ...props }: TPrivacyPolicyProps) {
  window.scrollTo(0, 0);


  return (
    <>
      <StructurePages
        module={
          <>
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:gap-16 mt-8 nd:mt-0 md:mt-0 lg:mt-16 py-20">
              <h1 className="text-5xl nd:text-3xl">Política de Privacidade</h1>
              <div className="w-1/2 text-lg nd:w-full nd:px-5">
                <p>
                  A presente Política contém informações sobre o tratamento de dados pessoais realizado pela Seedztech.
                  Aqui, você encontrará informações sobre a forma como seus dados pessoais são utilizados, e, se desejar
                  mais detalhes sobre seu uso, pode entrar em contato com nosso DPO, que responderá as dúvidas não cobertas por esta Política
                </p>
                <br />
                <p>
                  Como condição para acesso e uso das funcionalidades exclusivas no <i>https://seedztech.com.br/</i>, você declara que fez a leitura
                  completa e atenta desta Política, estando plenamente ciente, conferindo, assim, sua livre e expressa concordância com os
                  termos aqui estipulados, incluindo a coleta dos Dados aqui mencionados, bem como sua utilização para os fins abaixo
                  especificados. Caso você não esteja de acordo com as disposições desta Política, você deverá descontinuar o seu
                  acesso ou uso do <i>https://seedztech.com.br/.</i>.
                </p>
                <br />
                <p className="font-bold">
                  O que são dados pessoais?
                </p>
                <br />
                <p className="ml-5">
                  Dado pessoal diz respeito a qualquer informação relativa a uma pessoa que possa ser utilizada para identificá-la,
                  tanto individualmente quanto a partir de uma análise que envolva vários tipos de informação.
                </p>
                <br />
                <p className="font-bold">
                  Como coletamos Dados?
                </p>
                <br />
                <p className="ml-5">
                  Os Dados, incluindo Dados Pessoais, poderão ser coletados quando você os submete ou quando você interage com o <i>https://seedztech.com.br/.</i>.
                </p>
                <br />
                <p className="font-bold ml-10">
                  BASE DE DADOS
                </p>
                <p className="ml-10">
                  A base de dados formada por meio da coleta de Dados é de nossa propriedade e está sob nossa responsabilidade,
                  sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos
                  dos negócios descritos nesta Política.
                </p>
                <br />
                <p className="font-bold">
                  Quais dados nós utilizamos?
                </p>
                <br />
                <p className="font-bold ml-10">
                  DADOS CADASTRAIS
                </p>
                <br />
                <p className="ml-16 font-bold">
                  O que coletamos?
                </p>
                <p className="ml-20">Nome, Empresa, Telefone, E-mail, Mensagem</p>
                <br />
                <p className="ml-16">
                  <strong>Para que coletamos?</strong>
                </p>
                <ul className="ml-20">
                  <li>- Identificar você.</li>
                  <li>- Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</li>
                  <li>- Cumprir nossas obrigações legais e regulatórias.</li>
                </ul>
                <br />
                <p className="ml-10 font-bold">
                  DADOS DE IDENTIFICAÇÃO DIGITAL
                </p>
                <br />
                <p className="ml-16 font-bold">
                  O que coletamos?
                </p>
                <ul className="ml-20">
                  <li>- Endereço IP e Porta Lógica de Origem</li>
                  <li>- Dispositivo (versão do sistema operacional)</li>
                  <li>- Geolocalização</li>
                  <li>- Registros de data e horário cada ação que você realizar</li>
                  <li>- Quais telas você acessou</li>
                  <li>- ID da sessão</li>
                  <li>- Cookies</li>
                </ul>
                <br />
                <p className="ml-10 font-bold ml-16">Para que coletamos?</p>
                <ul className="ml-20">
                  <li>- Identificar você.</li>
                  <li>- Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</li>
                  <li>- Cumprir nossas obrigações legais e regulatórias.</li>
                </ul>
                <br />
                <p className="ml-10 font-bold">DADOS NECESSÁRIOS</p>
                <p className="ml-10">
                  Muitos de nossos serviços dependem diretamente de alguns dados informados na tabela acima, principalmente dados cadastrais.
                  Caso você opte por não fornecer alguns desses dados, podemos ficar impossibilitados de prestar total ou parcialmente
                  nossos serviços à você.
                </p>
                <br />
                <p className="ml-10 font-bold">ATUALIZAÇÃO E VERACIDADE DOS DADOS</p>
                <p className="ml-10">
                  Você é o único responsável pela precisão, veracidade ou falta dela em relação aos dados que você fornece
                  ou pela sua desatualização. Fique atento pois é de sua responsabilidade garantir a exatidão ou mantê-los atualizados.
                </p>
                <p className="ml-10">
                  Da mesma forma, nós não somos obrigados a processar ou tratar quaisquer dos seus dados se houver razões para crer
                  que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável, ou se você estiver
                  utilizando o <i>https://seedztech.com.br/</i> para quaisquer fins ilegais, ilícitos ou contrários à moralidade.
                </p>
              </div>
            </section>
          </>
        }
      />
    </>
  )
};