import { useState } from "react";
import { Icons } from "../../assets/icons/_index.icons";
import { Navigate } from "react-router-dom";
import { RoutesPath } from "../../routes/AppRoutes.routes";

type TFooterProps = {};

export default function Footer({ ...props }: TFooterProps) {
  const [naviteTo, setNavigateTo] = useState<string>("");

  return (
    <>
      {
        naviteTo &&
        <Navigate to={naviteTo} replace={true} />
      }
      <footer className="w-full h-auto px-[22rem] xl:px-[10rem] space-y-5 py-2 nd:px-2 md:px-10"
        style={{
          background: "#5B8E5A",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <section className="w-full nd:w-auto inline-flex gap-32 justify-end nd:gap-2">
          <div className="text-gray-50 p-2 space-y-5">
            <h3 className="text-lg whitespace-nowrap nd:text-base">Atendimento ao Cliente</h3>
            <div className="w-full flex flex-col space-y-1 nd:text-xs">
              <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.privacy)}>Política de Privacidade</a>
              <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.cookies)}>Política de Cookies</a>
              <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.terms)}>Termos e Condições</a>
              <a className="cursor-pointer">FAQ</a>
            </div>
          </div>
          <div className="text-gray-50 p-2 space-y-5">
            <h3 className="text-lg whitespace-nowrap nd:text-base">Sobre a SeedzTech</h3>
            <div className="w-full flex flex-col space-y-4">
              <div className=" space-y-1 flex flex-col nd:text-xs">
                <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.about)}>Nossa história</a>
                <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.portfolio)}>Marcas e designers</a>
                <a className="cursor-pointer" onClick={() => setNavigateTo(RoutesPath.contact)}>Contato</a>
              </div>

              <div className="inline-flex w-full justify-between">
                <Icons.whatsapp className="cursor-pointer" />
                <Icons.instagram className="cursor-pointer" onClick={() => window.open("https://www.instagram.com/seedztech/", "_blank")} />
                <Icons.facebook className="cursor-pointer" onClick={() => window.open("https://www.facebook.com/profile.php?id=61561022665818", "_blank")} />
                <Icons.linkedin className="cursor-pointer" onClick={() => window.open("https://www.linkedin.com/company/seedztech", "_blank")} />
              </div>
            </div>
          </div>
        </section>
        <div className="w-full text-gray-50 px-2 justify-between flex bottom-0 nd:text-xs" >
          <span className="whitespace-nowrap">Copyright © 2018 - {new Date().getFullYear()} SeedzTech</span>
        </div>
      </footer >
    </>
  )
};