import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";

import LogoSeedz from "../../assets/img/seedztech.png";

import { brightnessIdentify } from "../../utils/_index.utils";
import { RoutesPath } from "../../routes/AppRoutes.routes";

type THeader = {};

export default function Header({ ...props }: THeader) {
  const modules = [
    {
      name: "Início",
      _id: "header-inicio",
      onClick: RoutesPath.home,
    },
    {
      name: "Empresa",
      _id: "header-empresa",
      onClick: RoutesPath.about,
    },
    {
      name: "Portfólio",
      _id: "header-portifolio",
      onClick: RoutesPath.portfolio,
    },
    {
      name: "Contato",
      _id: "header-contato",
      onClick: RoutesPath.contact,
    },
  ];

  const ref = useRef(null);
  const [animationStarted, setAnimationStarted] = useState<boolean>(false);
  const [moduleFeatured, setModuleFeatured] = useState<string | null>(null);
  const [pathFeatured, setPathFeatured] = useState<string | null>(null);

  useEffect(() => {
    const header = ref.current;

    function handleYAxel(event: MouseEvent) {
      console.log({ event });
    }

    document.addEventListener("pointerdown", handleYAxel);

    setTimeout(() => {
      setAnimationStarted(true);
    }, 100); // Delay to ensure the initial class is applied

    return () => {
      document.removeEventListener("pointerdown", handleYAxel);
    };
  }, [ref]);

  useEffect(() => {

    setPathFeatured(window.location.pathname);

  }, [window.location.pathname]);

  return (
    <>
      {
        moduleFeatured &&
        <Navigate to={moduleFeatured} replace={true} />
      }
      <section
        className={
          (
            pathFeatured === RoutesPath.home ||
            pathFeatured === RoutesPath.dir
          ) ? "w-full h-auto min-h-[70vh] bg-[url('sectionHeader.png')] bg-cover bg-bottom flex relative justify-center"
            : "w-full flex relative justify-center h-auto"
        }
        style={{ backgroundPositionY: "67%" }}
      >
        <header
          ref={ref}
          className="w-full nd:h-auto md:h-auto nd:p-2 md:p-2 h-[9vh] flex justify-center items-center md:px-5 nd:px-5 px-80 xl:px-40 fixed z-50"
          style={{
            background: "rgba(41, 41, 41, 0.2)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5.5px)",
          }}
        >
          <div className="w-full h-9 justify-end flex items-center">
            <div className="inline-flex w-full nd:gap-10 gap-6 justify-between items-center">
              <div
                className={
                  (
                    pathFeatured === RoutesPath.home ||
                    pathFeatured === RoutesPath.dir
                  )
                    ? "w-full h-auto nd:px-7"
                    : "w-full h-auto"
                }
              >
                <img
                  src={LogoSeedz}
                  className="bg-cover nd:w-auto md:-auto md:max-w-[3.6vw] nd:max-w-[5.6vw] w-[3.6vw]"
                />
              </div>
              <div className="w-full h-full flex items-center space-x-6">
                {modules.map((item, i) => (
                  <button
                    key={i}
                    className="bg-none text-white text-2xl nd:text-xs md:text-md"
                    style={{
                      // color: brightnessIdentify()
                    }}
                    onClick={() => setModuleFeatured(item.onClick)}
                  >
                    {item.name}
                  </button>
                ))}
                <div></div>
              </div>
            </div>
          </div>
        </header>
        {
          (
            pathFeatured === RoutesPath.home ||
            pathFeatured === RoutesPath.dir
          ) &&
          <>
            <div className="flex w-auto max-w-[35vw] h-auto absolute top-1/2 left-1/3 nd:top-1/2 nd:left-1/4 -translate-x-1/2 -translate-y-1/2 flex-col space-y-6">
              <h1
                className={`w-full min-w-[18rem] transition-transform duration-2000 ${animationStarted ? "translate-y-0" : "translate-y-[15%]"}`}
              >
                <span className="text-white text-2xl nd:text-base font-bold whitespace-nowrap">
                  Plantando inovação, colhendo o futuro
                </span>
                <br />
                <span className="text-white text-5xl nd:text-3xl font-bold whitespace-pre-wrap break-words">
                  Tecnologia em cada semente
                </span>
              </h1>
              <hr className="h-px w-full bg-gray-200 min-w-[18rem]" />
              <p className="text-white min-w-[18rem]">
                Vamos além da criação de aplicativos. Compreendemos o DNA do seu
                negócio, resolvemos seus desafios e conduzimos você à conquista de
                seus objetivos por meio de tecnologia, expertise e metodologias
                inovadoras.
              </p>
            </div>
            <div
              className={
                `absolute top-[90%] nd:top-[95%] xl:top-[85%]
                w-auto min-w-[20vw] h-[15vh] inline-flex overflow-hidden font-bold text-[5.5rem] nd:text-5xl 
                font-mono transition-transform duration-1000 ${animationStarted ? "translate-x-0" : "translate-x-[-100%]"}`
              }
            >
              <h1
                className="font-bold text-[5.5rem] nd:text-5xl font-mono"
                style={{
                  backgroundImage: "linear-gradient(to bottom, #679864, #09240d)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                seedztech
              </h1>

              <div className="w-[5vw] h-[30vh] rounded-full bg-gradient-to-t from #679864 to #09240d -rotate-45"></div>
            </div>
          </>
        }
      </section>
    </>
  );
}
