import { Icons } from "../../assets/icons/_index.icons";
import StructurePages from "../StructurePages";
import { PortfolioGergelim } from "./_index.portfolio";



export default function PortfolioPage() {
  window.scrollTo(0, 0);

  return (
    <StructurePages
      module={
        <>
          <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:gap-16 mt-8 nd:mt-16 md:mt-16 py-20">
            {
              <PortfolioGergelim />
            }
          </section>
        </>
      }
    />
  )
};