
import './index.css';

import { AppRoutes } from './routes/_index.routes';

export default function AppRoot() {

  return (
    <AppRoutes />
  );
};