
import CaseGergelim from "../../../assets/img/caseGergelim.png";

export default function PortfolioGergelim() {


  return (
    <>
      <div className="w-full relative h-auto gap-32 nd:gap-16 flex flex-col">
        <div className="w-full flex flex-col gap-5">
          <h1 className="text-5xl nd:text-2xl text-gray-400">Gergelim</h1>
          <hr className="h-px bg-gray-400 w-full" />
        </div>
        <hr className="absolute rounded-full top-0 right-1/2 w-[20vw] nd:w-[50%] nd:left-0 h-full nd:h-1/2 rotate-45 bg-slate-100 -z-10" />
        <div className="inline-flex w-full gap-32 nd:flex nd:flex-col nd:gap-8">
          <img
            className="w-auto max-w-[60%] max-h-[50vh]"
            src={CaseGergelim}
          />
          <div className="flex flex-col gap-8 text-gray-400">
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Sobre o cliente</h3>
              <p>
                Dedicada a oferecer soluções personalizadas que atendem
                às necessidades específicas de cada cliente. Com um foco constante em excelência e inovação,
                a empresa se empenha em garantir que cada produto não só cumpra, mas supere as expectativas,
                refletindo a identidade única de cada marca.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Serviços</h3>
              <ul className="list-disc list-outside pl-5">
                <li>Prototipação</li>
                <li>Documentação</li>
                <li>Design</li>
                <li>Desenvolvimento Backend</li>
                <li>Desenvolvimento Frontend Web</li>
                <li>Manutenção</li >
                <li>Consultoria</li >
              </ul >
            </div >
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Objetivo</h3>
              <p>
                Impulsionar as vendas da loja através de facilidades com meio de pagamento online,
                logistica dos produtos comprados. Além de funcionalidades destinadas ao gerenciamento
                da loja com extração de relatórios.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Desafios</h3>
              <p>
                Tornar o site intuitivo e atrativo para potênciais clientes, oferencendo um sistema de
                vendedor online personalizado para cada cliente de acordo com suas buscas.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Funcionalidades</h3>
              <ul className="list-disc list-outside pl-5">
                <li>Gestão de Categorias</li>
                <li>Gestão de Estoque</li>
                <li>Gestão de Pedidos</li >
                <li>Gestão de Produtos</li >
                <li>Gestão de Usuários</li >
                <li>Vendedor virtual personalizado</li >
                <li>Extração Relatórios</li >
              </ul >
            </div >
            <div className="w-full gap-2 flex flex-col">
              <h3 className="text-3xl nd:text-xl">Lançamento</h3>
              <p>Outubro de 2024</p>
            </div>
          </div >

        </div >
      </div >
    </>
  )
};