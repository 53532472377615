import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer, Header } from "../components/_index.components";
import { useState } from "react";
import { RoutesPath } from "../routes/AppRoutes.routes";
import { SYS_SEEDZTECH } from "../utils/_index.utils";

type TStructurePages = {
  module: React.ReactNode;
};

const menuIn = false;

export default function StructurePages(props: TStructurePages) {
  const [hideWarning, setHideWarning] = useState<boolean>(
    localStorage.getItem(SYS_SEEDZTECH + RoutesPath.cookies) === "true"
      ? true
      : false
  );

  const [naviteTo, setNavigateTo] = useState<string>("");

  function handleHideWarning(e: any) {
    e.preventDefault();

    localStorage.setItem(
      SYS_SEEDZTECH + RoutesPath.cookies,
      "true"
    );

    setHideWarning(true);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {
        naviteTo &&
        <Navigate to={naviteTo} replace={true} />
      }
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow relative bg-none w-full px-80 xl:px-40 nd:px-0 md:px-0 lg:px-0">
          <div className="flex justify-end w-full h-full">
            <div className="flex justify-start items-start w-full bg-none p-1">
              <div className="flex flex-col bg-datagrid-background w-full h-full rounded-md items-center justify-center max-h-full nd:p-2 md:p-5 lg:p-5">
                {props.module}
              </div>
            </div>
          </div>
        </div>
        {
          !hideWarning &&
          <div className="fixed bottom-5 left-5 nd:left-2 nd:right-2 shadow-md w-auto h-auto bg-[#e0e9dc] rounded-md p-2 z-50">
            <div className="inline-flex gap-8 nd:gap-4 justify-center items-center">
              <p className="text-xs w-full text-gray-400">
                Utilizamos cookies para melhorar a sua experiência em nosso site.
                Ao continuar navegando você concorda com a nossa política de privacidade.
                <strong
                  onClick={() => setNavigateTo(RoutesPath.privacy)}
                  className="text-blue-400 ml-1 cursor-pointer"
                >
                  Saiba mais
                </strong>
              </p>
              <button
                className="w-auto max-w-[30%] h-full p-2 border border-[#A8D696] rounded-md bg-[#A8D696]"
                onClick={handleHideWarning}
              >
                <p className="whitespace-nowrap text-white text-xs">Estou de Acordo</p>
              </button>
            </div>
          </div>
        }
        <Footer />
      </div>
    </>
  );
}
