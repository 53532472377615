import { StructurePages } from "../_index.pages";

import Life from "../../assets/img/life.svg";
import Mission from "../../assets/img/mission.png";


export default function AboutPage() {
  window.scrollTo(0, 0);

  return (
    <>
      <StructurePages
        module={
          <>
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:mt-0 mt-8 py-20 nd:px-5 nd:gap-2">
              <div className="w-full">
                <h1 className="text-5xl nd:text-3xl whitespace-nowrap">Um pouco mais sobre nós...</h1>
              </div>
              <div className="w-full flex gap-20 nd:flex-col nd:gap-5">
                <div className="rounded-figma h-auto w-auto text-gray-400 py-8 gap-4 flex flex-col">
                  <h1 className="text-3xl nd:text-2xl">Na SeedzTech</h1>
                  <div className="w-full flex flex-col gap-2">
                    <hr className="h-px bg-gray-400 w-full" />
                    <p className="text-lg nd:text-base">
                      Nossa missão é trazer soluções tecnológicas inovadoras, sempre fundamentando nosso trabalho desde o início do projeto.
                    </p>
                    <p className="text-lg nd:text-base">
                      O nome Seedz, que significa "sementes", representa a estrutura essencial de tudo, simbolizando o começo e o ciclo de crescimento.
                    </p>
                    <p className="text-lg nd:text-base">
                      Os primeiros passos, como o nascimento de um website, é uma etapa fundamental para garantir o sucesso e a evolução de qualquer projeto.
                    </p>
                    <p className="text-lg nd:text-base">
                      Desenvolvemos soluções tecnológicas personalizadas para atender às suas necessidades e revolucionar o seu negócio.
                    </p>
                  </div>
                </div>
                <div className="w-auto min-w-[25%] xl:min-w-[35%] rounded-md overflow-hidden flex justify-center items-center">
                  <img
                    className="w-full bg-cover rounded-md filter"
                    src={Life}
                    alt="seedztech"
                  />
                </div>
              </div>
            </section>
            <hr className="h-px bg-gray-400 w-full" />
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:mt-0 mt-8 py-20 nd:px-5 nd:gap-2">
              <div className="w-full flex gap-20 nd:flex-col nd:gap-5">
                <div className="w-auto min-w-[25%] xl:min-w-[35%] rounded-md overflow-hidden flex justify-center items-center">
                  <img
                    className="w-full bg-cover rounded-md filter"
                    src={Mission}
                    alt="seedztechMission"
                  />
                </div>
                <div className="w-full h-auto flex flex-col gap-2">
                  <div className="rounded-figma h-auto w-auto text-gray-400 py-8 gap-4 flex flex-col">
                    <h1 className="text-3xl nd:text-xl">Missão</h1>
                    <p>
                      Nossa missão é impulsionar o sucesso de empresas que buscam, por meio da tecnologia,
                      alcançar seus objetivos. Com soluções inovadoras e personalizadas, visamos transformar
                      desafios em oportunidades, promovendo eficiência, crescimento e competitividade
                      para nossos clientes.
                    </p>
                  </div>
                  <div className="rounded-figma h-auto w-auto text-gray-400 py-8 gap-4 flex flex-col">
                    <h1 className="text-3xl nd:text-xl">Visão</h1>
                    <p>
                      Ser reconhecida como a startup líder em soluções tecnológicas inovadoras, capaz de
                      transformar a maneira como empresas operam e alcançam seus objetivos. Almejamos criar
                      um ecossistema onde tecnologia e negócios caminhem juntos, gerando valor sustentável
                      e impacto positivo em todas as áreas que tocamos.
                    </p>
                  </div>
                  <div className="rounded-figma h-auto w-auto text-gray-400 py-8 gap-4 flex flex-col">
                    <h1 className="text-3xl nd:text-xl">Valores</h1>
                    <p>Inovação Contínua: Buscamos constantemente novas ideias e abordagens para resolver os desafios tecnológicos e de negócios de nossos clientes.</p>
                    <p>Excelência: Comprometemo-nos a entregar serviços e soluções de alta qualidade que superem as expectativas.</p>
                    <p>Transparência: Mantemos uma comunicação clara e honesta com nossos clientes, parceiros e colaboradores.</p>
                    <p>Colaboração: Valorizamos o trabalho em equipe e as parcerias, acreditando que a colaboração é a chave para a inovação e o sucesso.</p>
                    <p>Foco no Cliente: Colocamos os objetivos e necessidades de nossos clientes no centro de tudo o que fazemos.</p>
                    <p>Sustentabilidade: Promovemos práticas sustentáveis e responsáveis, visando o bem-estar das futuras gerações.</p>
                    <p>Adaptabilidade: Estamos sempre prontos para nos adaptar às mudanças do mercado e às novas tecnologias, garantindo a relevância e eficácia de nossas soluções.</p>
                  </div>
                </div>

              </div>
            </section>
          </>
        }
      />

    </>
  )
};