import { GiShoppingCart } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdConstruction } from "react-icons/md";

import {
  BsInstagram,
  BsWhatsapp,
  BsFacebook,
  BsLinkedin
} from "react-icons/bs";

import {
  GrFormNext,
  GrFormPrevious
} from "react-icons/gr";


export const Icons = {
  shoppingCart: GiShoppingCart,
  profile: FaUserCircle,
  services: RiCustomerService2Line,
  next: GrFormNext,
  previous: GrFormPrevious,
  instagram: BsInstagram,
  whatsapp: BsWhatsapp,
  facebook: BsFacebook,
  linkedin: BsLinkedin,
  construction: MdConstruction,
};