import { useState } from "react";

type TContactPageHooksProps = {};
export type TContactPageHooksStates = TContactPageHooksProps & {
  message: string;
  fullName: string;
  company: string;
  phoneNumber: string;
  email: string;
  setMessage: (d: string) => void;
  setFullName: (d: string) => void;
  setCompany: (d: string) => void;
  setPhoneNumber: (d: string) => void;
  setEmail: (d: string) => void;
  handleSubmit: (d: any) => void;

};

export const ContactPageHooks = ({ ...props }: TContactPageHooksProps): TContactPageHooksStates => {

  const [message, setMessage] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleSubmit = (e: any) => {
    e.preventDefault();


  };

  return {
    ...props,
    message,
    fullName,
    company,
    phoneNumber,
    email,
    setFullName,
    setCompany,
    setPhoneNumber,
    setEmail,
    setMessage,
    handleSubmit,
  };
};